<!-- 站点主账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <!-- <el-input placeholder="搜索" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;margin-right:auto;" @input="curr=1;getList()"></el-input> -->
            <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="站点名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user_name" label="负责人名称" align="center" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="营业时间" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.start_time}}-{{scope.row.end_time}}
                </template>
            </el-table-column> -->
            <el-table-column prop="address" label="地址" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button plain type="warning" size="mini" @click="goSub(scope.row)">进入站点</el-button>
                    <el-button plain type="success" size="mini" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="mini" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {

        // goSub
        goSub(row) {
            this.http.post('/sales.site/setSalesSite', { id: row.id }).then(() => {
                sessionStorage.setItem('bureau_name', row.name);
                sessionStorage.setItem('bureau_id', row.id);
                this.$router.push({
                    path: '/subOverview'
                })
            })
        },
        getList: function() {
            this.http.post('/sales.site/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },
        edit: function(row) {
            if(row) {
                row['time'] = [row.start_time, row.end_time];
            }
            this.$router.push({
                path: '/bureauAdd',
                query: {
                    // id: row?Number(row.id):'new',
                    id: row ? JSON.stringify(row) : 'new',
                }
            });
        },
        handleDel(id) {
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/sales.site/del', {
                    id: id
                }).then(() => {
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
                })
            })
        },

        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
